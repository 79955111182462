import React from 'react'
import {Link} from "gatsby"
import Img from "gatsby-image"
import moment from 'moment-timezone'
import 'moment/locale/zh-cn'
import Image from "../image"

const Header = ({author, title, date, created, background, img}) =>{
    moment.locale('zh-cn')
    return(
        <div className={"title "+background}>
            <div className="container" >
                <div className="row">
                    <div className="col-12 col-md-8">
                        <h1>{title}</h1>
                        <div className="update">{
                            author &&<Link to={"/about/us/#"+author.id} className="author">
                                <div className="profile-icon">
                                    <Img fixed={author.profilepicture.childImageSharp.fixed} alt={author.name} />
                                </div>
                                <div className="profile">
                                    <p className="name" >{author.name}</p>
                                    <p className="bio" >{author.bio}</p>
                                </div>
                                
                            </Link>
                        }{
                            (date && created) && <div className="time">
                                <p>更新于: <span className="badge badge-primary">
                                        <time dateTime={moment.tz(date,"Asia/Hong_Kong").toISOString()} >{moment(date).format('LL')}</time>
                                    </span> | 创建于: <span class="badge badge-success">
                                        <time dateTime={moment.tz(created,"Asia/Hong_Kong").toISOString()} >{moment(created).format('LL')}</time>
                                    </span>
                                </p>
                            </div>
                        }</div>
                    </div>
                    <div className="col-12 col-md-4 illustrations">
                        {img !== null && <Image load="lazy" src={img.childImageSharp} url={img.publicURL} alt={title + "图片"} /> }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header