import React from 'react';
import {graphql} from 'gatsby'
import Layout from "../components/layout"
import Markdown from "../components/post/markdown"
import SEO from "../components/seo"
import Header from "../components/post/header"
import Commento from "../components/post/comment"
import "../styles/parts/post.sass"

export const query = graphql`
    query ($path: String!) {
        mdx(frontmatter: {path: {eq: $path}}) {
            frontmatter {
              date
              created
              path
              recommend
              title
              head
              description
              background
              img{
                childImageSharp {
                  fluid(maxHeight: 200) {
                    ...GatsbyImageSharpFluid
                  }
                }
                extension
                publicURL
              }
              og_img{
                extension
                publicURL
              }
              author {
                id
                bio
                name
                profilepicture {
                  childImageSharp{
                    fixed(width: 30) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              structured
              structured_img {
                publicURL
              }
              structured_name
              structured_rate
              structured_price
              breadcrumb{
                name
                item
              }
              faq{
                que
                ans
              }
              review{
                rate
                content
              }
            }
            body
            headings {
                depth
                value
            }
        }
    }
`
const Post = ({data}) => {
    const { mdx } = data // data.markdownRemark holds your post data
    const { frontmatter, body, headings } = mdx
    const author = frontmatter.author
    return (
        <Layout>
            <div className="post">
                <SEO title={frontmatter.head} author={author.name} des={frontmatter.description} frontmatter={frontmatter} type={frontmatter.structured}/>
                <Header author={author} title ={frontmatter.title} 
                    date={frontmatter.date} 
                    created={frontmatter.created}
                    background={frontmatter.background} img={frontmatter.img}
                 />
                <div className="content container">
                  <Markdown 
                    data={body}
                    headings={headings} 
                    path={frontmatter.path}
                    title={frontmatter.head}
                    VPNType={frontmatter.recommend === "out"? 0: 1}
                  />
                </div>
                <div className="container">
                    {/* <div id={`commento`} /> */}
                    <Commento id={frontmatter.path} />
                </div>
            </div>
        </Layout>
    )
    
}

export default Post