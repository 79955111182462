import React from "react"
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    RedditShareButton,
    RedditIcon
  } from "react-share";

const Share = ({title, url}) => (
    <div id="share-article">
        <FacebookShareButton quote={title} url={url} >
            <FacebookIcon size={36} round={true} />
        </FacebookShareButton>
        <span>&nbsp;</span>
        <TwitterShareButton title={title} via="wall101_com" url={url}>
            <TwitterIcon size={36} round={true} />
        </TwitterShareButton>
        <span>&nbsp;</span>
        <RedditShareButton title={title} url={url} >
            <RedditIcon size={36} round={true} />
        </RedditShareButton>
    </div> 
)

export default Share