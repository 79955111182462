import React from "react"
import { Helmet } from "react-helmet"

const SEO = ({title, author, des, frontmatter, type })=>{
  const site = "https://wall101.com"
  if (frontmatter){
    var schema = {
      "@context": "http://schema.org"
      // "logo":{
      //   "@type": "Organization",
      //   "url": site + frontmatter.path,
      //   "logo": "https://wall101.com/favicon.svg"
      // }
    }
    // image
    if (frontmatter.structured_img){
      schema["image"] = frontmatter.structured_img.map( img => site+img.publicURL)
    }
    // breadcrumb
    if (frontmatter.breadcrumb){
      const itemlist = frontmatter.breadcrumb.map((bread,index)=>{
        var crumb = {
          "@type": "ListItem",
          "position": index+1,
          "name": bread.name
        }
        if (bread.item){
          crumb["item"] = site+bread.item
        }
        return crumb
      })
      const breadcrumb = {
        "@type": "BreadcrumbList",
        "itemListElement": itemlist
      }
      schema["breadcrumb"] = breadcrumb
    }
    // article
    if (type === "article"){
      const dateCreated = new Date(frontmatter.created)
      const dateEdited = new Date(frontmatter.date)
      const publisher = {
        "@type": "Organization",
        "name": "Wall 101",
        "logo": {
          "@type": "ImageObject",
          "url": "https://wall101.com/favicon.svg"
        }
      }
      var article = schema
      article["@type"] = "Article"
      article["headline"] = frontmatter.head
      article["datePublished"] = dateCreated.toISOString()
      article["dateModified"] = dateEdited.toISOString()
      article["publisher"] = publisher
      // add FAQ
      if (frontmatter.faq){
        const entity = frontmatter.faq.map((qa)=>{
          return {
            "@type": "Question",
            "name": qa.que,
            "acceptedAnswer": {
              "@type": "Answer",
              "text": qa.ans
            }
          }
        })
        article["FAQ"] = {
          "@type": "FAQPage",
          "mainEntity": entity
        }
      }
      
    }
    // software
    if (type === "software"){
      var software = {
        "@context": "http://schema.org",
        "@type": "SoftwareApplication",
        "operatingSystem": "IOS Android Windows Mac",
        "applicationCategory": "UtilitiesApplication",
      }
      const rating = {
        "@type": "AggregateRating",
        "ratingValue": frontmatter.structured_rate,
        "bestRating": 10,
        "ratingCount": "6"
      }
      const offer = {
        "@type": "Offer",
        "price": frontmatter.structured_price,
        "priceCurrency": "USD"
      }
      const review =  {
        "@type": "Review",
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": frontmatter.review.rate
        },
        "author": {
          "@type": "Person",
          "name": "Mark Zhang"
        },
        "reviewBody": frontmatter.review.content
      }
      software["name"] = frontmatter.structured_name
      software["aggregateRating"] = rating
      software["offers"] = offer
      software["review"] = review
    }
  }
  
  
  return (
    <Helmet>
      <title>{title}</title>
      {author && <meta name="author" content={author} /> }
      {des && <meta name="description" content={des} /> }
      {type === "article" && <script type="application/ld+json">
        {JSON.stringify(article)}
      </script>}
      {type === "software" && <script type="application/ld+json">
        {JSON.stringify(software)}
      </script>}
      <meta property="og:title" content={title} /> 
      <meta property="og:type" content="website" />
      <meta property="og:url" content={site+frontmatter.path} />
      {des && <meta property="og:description" content={des} /> }
      {frontmatter.og_img && <meta property="og:image" content={site+frontmatter.og_img.publicURL} /> }
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:site" content="@wall101_com" />
      <meta name="twitter:url" content={site + frontmatter.path} />
      {des && <meta name="twitter:description" content={des} /> }
      {frontmatter.og_img && <meta name="twitter:image" content={site+frontmatter.og_img.publicURL} /> }
    </Helmet>
  )
}
export default SEO