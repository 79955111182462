import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const ReferIn = ({vpn, p, keyword}) =>{
    const data = useStaticQuery(graphql`
      query {
        allReferJson {
          edges {
            node {
              vpn
              name
              url
            }
          }
        }
      }
    `)

    const content = data.allReferJson.edges
    const item = content.filter((refer)=> {return refer.node.vpn === vpn;})[0]
    const link = item.node.url
    let url;
    if (keyword !== undefined){
      const newLink = link.replace("/vpn/","/key/")
      url = newLink +"/?keyword="+keyword
    }else{
      url = link
    }
    

    return (
        <OutboundLink
            href={url} 
            target="_blank"
            rel="noreferrer nofollow"
        >{p === undefined ? item.node.name: p}
        </OutboundLink>
    )
}

export default ReferIn