import React, { useState, useRef } from "react"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import TOC from "./toc"
import Recommend from "./recommend"
import Share from "./share"
import Slugger from 'github-slugger'

// MDX components
import {Link} from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Refer from "../card/refer"
import ReferIn from "../card/refer-inline"
import CardRedirect from "../card/redirect"
import {M,L} from "./width"
// icons
import menu from "../../icons/card/menu.svg"
import cross from "../../icons/header/cross.svg"

const shortcodes = { Link, Refer, CardRedirect, ReferIn, AnchorLink, M, L }
const slugger = Slugger.slug


const Markdown = ({data,headings,path,title,VPNType})=>{
    const [menuState, setMenu] = useState(false)
    const articleRef = useRef(null)
    const recommendRef = useRef(null)

    const scrollArticle = () => articleRef.current.scrollIntoView()
    const scrollRecommend = () => recommendRef.current.scrollIntoView()

    return(
        <div className="row" >
            <div className="col-12 col-md-9 main-body" >
                <MDXProvider components={shortcodes}>
                    <MDXRenderer >
                    {data}
                    </MDXRenderer>
                </MDXProvider>
            </div>
            <div className="col-12 col-md-3 right" >
                <div className="sidebar desktop">
                    <TOC headings={headings} path={path}/>
                    <Share title={title} url={"https://wall101.com"+path} />
                    <Recommend type={VPNType} />
                </div>
                <button id="mobile-menu" className={menuState? "hidden": null} 
                    onClick={()=>setMenu(true) }
                >
                    <img id="mobile-menu-icon" src={menu} alt="导航" />
                </button>
                <div id="mobile-sidebar" className={menuState? null: "hidden"}>
                    <div id="mobile-sidebar-head">
                        <div id="mobile-sidebar-head-anchors">
                            <p>
                                <button onClick={scrollArticle} >文章目录</button>
                                <button onClick={scrollRecommend} >VPN推荐</button>
                            </p>
                        </div>
                        <button id="mobile-sidebar-close" 
                            onClick={()=>setMenu(false) }
                            >
                            <img id="mobile-sidebar-close-icon" 
                                src={cross} 
                                alt="关闭菜单"/>
                        </button>
                    </div>
                    <div id="mobile-sidebar-content">
                        <div ref={articleRef} className="toc" >
                            <h5>文章目录</h5>
                            <ul id="mobile-sidebar-toc-links">{
                                headings.map((head)=>{
                                    const slug = slugger(head.value)
                                    return(
                                    <>
                                    { head.depth === 2 && <li 
                                        className="mobile-sidebar-toc-link"
                                        onClick={()=>setMenu(false) }
                                        key={head.value+"-mobile"}>
                                        <AnchorLink to={path+"#"+slug} >{head.value}</AnchorLink>
                                        </li>}
                                    </>
                                    )
                                })
                            }</ul>
                            <Share title={title} url={"https://wall101.com"+path} />
                        </div>
                        <div ref={recommendRef} >
                            <Recommend type={VPNType} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default Markdown