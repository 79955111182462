import React from "react"

const M = ({children})=>(
    <div className="m-width width">
        {children}
    </div>
)

const L = ({children})=>(
    <div className="l-width width">
        {children}
    </div>
)

export {
    M,
    L
}
