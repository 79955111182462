import React, { useEffect, useState } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Slugger from 'github-slugger'

const slugger = Slugger.slug

const TOC = ({headings, path}) =>{
    const [activeHash, setActiveHash] = useState(``)
    let h2
    var data = []
    var mappings = {}
    for (var i=0;i<headings.length;i++){
      var head = headings[i]
      if (head.depth === 2){
        h2 = slugger(head.value)
        mappings[h2] = h2
      }
      if (head.depth === 3){
        mappings[slugger(head.value)] = h2
      }
      head.h2 = h2
      data.push(head)
    }

    useEffect(() => {
        const observer = new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                // setActiveHash2(mappings[entry.target.id])
                setActiveHash(entry.target.id)
              }
            })
          }
        )
        const itemIds = document.querySelectorAll('.post .content .main-body h2,h3[id]')
    
        itemIds.forEach(id => {
          observer.observe(id)
        })
        return () => {
          itemIds.forEach(id => {
            observer.unobserve(id)
          })
        }
      }, [])
    
    const activeHash2 = mappings[activeHash]
    
    return(
        <div className="toc">
            <h5>导航栏</h5>
            <ul className="toc-links" id="navbar-toc">{
                data.map((head)=>{
                  const slug = slugger(head.value)
                  return(
                    <>
                    { head.depth === 2 && <li 
                      className={"l2 url " + ((slug === activeHash ) ? "active" : "")} 
                      key={head.value}>
                        <AnchorLink to={path+"#"+slug} >{head.value}</AnchorLink>
                        </li>}
                    { (head.depth === 3)&& <li 
                      className={"l3 url " + ((slug === activeHash) ? "active " : " ")+(head.h2 === activeHash2 ? "" :"hidden")} 
                      key={head.value}>
                        <AnchorLink to={path+"#"+slug} >{head.value}</AnchorLink>
                        </li>}
                    </>
                  )
                })
            }</ul>
        </div>
    )
}

export default TOC