import React from "react"
import {Link} from "gatsby"
import "../../styles/parts/redirect.sass"
import arrow from "../../icons/card/arrow_r.svg"

const CardRedirect =({p1,name,p2,url})=>(
    <Link className="card-redir" to={url}>
        <div className="words" >
            <p>{p1}<span className="link">{name}</span></p>
            <p className="second">{p2}</p>
        </div>
        <div className="icon">
            <span><img src={arrow} alt="点击访问" /></span>
        </div>
    </Link>
)

export default CardRedirect