import React from "react"
import {useStaticQuery, graphql } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Recommend = ({type})=>{
    const data = useStaticQuery(graphql`
      query {
        allReferJson {
          edges {
            node {
              vpn
              name
              type
              rank_out
              rank_in
              url
              zhifubao
              recommend{
                tag
              }
            }
          }
        }
      }
    `)
    const allVpns = data.allReferJson.edges
    let vpns
    if (type === 0){
      vpns = allVpns.filter((vpn)=> {
        const recommend = vpn.node.recommend
        return recommend !== null && vpn.node.type.includes("out")

      })
      vpns.sort((a,b)=> a.node.rank_out > b.node.rank_out ? 1 : -1)
    }
    if (type === 1){
      vpns  = allVpns.filter((vpn)=> {
        const recommend = vpn.node.recommend
        return recommend !== null && vpn.node.type.includes("in")
      })
      vpns.sort((a,b)=> a.node.rank_in > b.node.rank_in ? 1 : -1)
    }
    const tops = vpns.slice(0,3)
    return (
        <div id="recommend">
            <h5>推荐VPN</h5>
            <ul id="rec-linkgs">{
                    tops.map((vpn)=>(
                        <li className="rec-li" key={vpn.node.vpn} >
                            <OutboundLink href={vpn.node.url} className="rec-link" target="_blank" rel="noreferrer nofollow">
                                <p><span className="name">{vpn.node.name}</span></p>
                                <p>
                                  {vpn.node.zhifubao && <span><span class="badge badge-primary"> 支付宝 </span> &nbsp;</span>}
                                  <span className="sales badge badge-warning">{vpn.node.recommend.tag}</span>
                                </p>
                            </OutboundLink>
                        </li>
                    ))
                }</ul>
        </div>
    )
}

export default Recommend